import { createGlobalStyle, css } from "styled-components";

export const GlobalStyles = createGlobalStyle(
  ({ theme }: { theme: any }) => css`
    /* Box sizing rules */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      ${theme.custom &&
      css`
        --main-color: ${theme.custom.colors.main};
        --secondary-color: ${theme.custom.colors.secondary};
        --background-color: ${theme.custom.colors.background};
        --text-color: ${theme.custom.colors.text};
        --header-text-color: ${theme.custom.colors.headerText};
        --primary-button-text-color: ${theme.custom.colors.primaryButtonText};
        --primary-button-hover-color: ${theme.custom.colors.primaryButtonHover};
        --component-background-color: ${theme.custom.colors
          .componentBackground};
        --border-color: ${theme.custom.colors.border};
        --input-border-color: ${theme.custom.colors.inputBorder};
        --hover-background-color: ${theme.custom.colors.hoverBackground};
        --box-shadow-color: ${theme.custom.colors.boxShadow};
        --selected-color: ${theme.custom.colors.selected};
        --secondary-text-color: ${theme.custom.colors.secondaryText};
        --content-background-color: ${theme.custom.colors.contentBackground};
        --disabled-color: ${theme.custom.colors.disabled};
        --loader-color: ${theme.custom.colors.loader};
        --live-event-card-background-color: ${theme.custom.colors
          .liveEventCardBackground};
      `}
      --variables-loaded: yes;
    }

    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    figure,
    blockquote,
    dl,
    dd {
      margin: 0;
    }

    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
    ul[role="list"],
    ol[role="list"] {
      list-style: none !important;
    }

    li {
      list-style-type: none;
    }

    /* Set core root defaults */
    html:focus-within {
      scroll-behavior: smooth;
    }

    /* Set core body defaults */
    body {
      min-height: 100vh;
      text-rendering: optimizeSpeed;
      line-height: 1.5;
      ${theme.custom &&
      css`
        color: ${theme.custom.colors.text};
      `};
    }

    #root {
      min-height: 100vh;
    }

    /* A elements that don't have a class get default styles */
    a:not([class]) {
      text-decoration-skip-ink: auto;
    }

    /* Make images easier to work with */
    img,
    picture {
      max-width: 100%;
      display: block;
    }

    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
      html:focus-within {
        scroll-behavior: auto;
      }

      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
      }
    }
  `
);
