import React, { useMemo } from "react";
import styled, { ThemeProvider, useTheme } from "styled-components";
import { ArtistModalProvider } from "./Artist/Components";
import { useUser } from "auth";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuthContext } from "Context";
import { themeOverrides } from "./Artist/Components/themeOverrides";
import { Loader } from "Components";

const ArtistEventList = React.lazy(() => import("./Artist"));
const ArtistEvent = React.lazy(() => import("./Artist/Event"));

export const Routes = () => {
  const { claims, signOut } = useUser();
  const defaultTheme = useTheme();
  const updatedTheme = useMemo(() => {
    return themeOverrides(defaultTheme);
  }, [defaultTheme]);
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext();
  const isAccessAllowed = isAdmin || isArtist;
  if (!isAccessAllowed) {
    signOut();
    return <></>;
  }
  return (
    <Wrapper>
      <React.Suspense fallback={<Loader />}>
        <ThemeProvider theme={updatedTheme}>
          <ArtistModalProvider>
            <Switch>
              <Route exact path="/" component={ArtistEventList} />
              <Route path="/:eventId" component={ArtistEvent} />
              <Redirect to="/" />
            </Switch>
          </ArtistModalProvider>
        </ThemeProvider>
      </React.Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.branding.bodyFont};
  width: 100%;
  flex: 1;
  position: relative;
  background-color: ${({ theme }) =>
    theme.custom?.background || "var(--background-color)"};

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;
